import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { Container } from "reactstrap";
import { Footer, Home, Navigation, Gallery } from "./components";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

function App() {
  return (
    <Container className="app-container">
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/gallery" Component={Gallery} />
        </Routes>
        <Footer />
      </Router>
    </Container>
  );
}

export default App;
