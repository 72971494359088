import { cloneDeep } from "lodash";

const getLogoImage = () =>
  cloneDeep({
    src: require("../../Home/components/HomeGallery/photos/misc/(edited) IMG_2241.png"),
    alt: "logo",
  });

const getInstagramLogo = () =>
  cloneDeep({
    src: require("../../Home/components/HomeGallery/photos/misc/logo-ig-png-32464.png"),
    alt: "instagram logo",
  });

export { getLogoImage, getInstagramLogo };
