import React from "react";
import { Container } from "reactstrap";
import "./AboutMe.scss";

const AboutMe = () => {
  return (
    <Container className="about-me-container">
      <h3>About Me</h3>
      Hello there, and welcome to my website! <br />
      <br />
      My name is Kelsey Devine, I'm a passionate tattoo artist who's been honing
      my craft since 2021. When it comes to tattooing, I'm all about creating
      meaningful pieces that tell a story and speak to each individual's unique
      personality and experiences. <br />
      <br />
      As someone who values inclusivity and creating a safe space for all, I'm
      proud to say that my studio is LGBTQIA+ friendly. As a trauma-informed
      tattoo artist, I understand the importance of creating a comfortable and
      healing environment for my clients. My goal is to provide a positive
      experience for each person who chooses to get inked by me. <br />
      <br />
      When it comes to my artistic style, I love to incorporate a
      fantasy-inspired vibe and bold, eye-catching colors. But at the end of the
      day, my goal is always to create a tattoo that you can be proud to wear
      for the rest of your life. Thank you for considering me as your tattoo
      artist. I'm dedicated to making your tattoo experience as comfortable and
      positive as possible, and I can't wait to work with you to bring your
      vision to life.
    </Container>
  );
};

export default AboutMe;
