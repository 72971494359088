import React from "react";
import { Navbar, Nav, NavItem } from "reactstrap";
import "./Footer.scss";

const Footer = () => {
  const args = {
    expand: "md",
    container: "fluid",
  };

  return (
    <Navbar {...args} className="app-footer">
      <div className="footer-left">
        <small href="/" className="footer-brand">
          &copy; Copyright 2023 Kelsey Devine
        </small>
      </div>
      <div className="footer-right">
        <Nav className="me-auto footer-inner" navbar>
          <NavItem className="footer-item"></NavItem>
        </Nav>
      </div>
    </Navbar>
  );
};

export default Footer;
