import React from "react";
import "./Location.scss";

const Location = () => {
  return (
    <div className="location-div">
      <iframe
        className="location-iframe"
        width="100%"
        title="location-iframe"
        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=2203%20Dunn%20Ave%20Suite%203,%20Jacksonville,%20FL%2032218+(9zero4%20Ink%20&amp;%20Body%20Art)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      >
        <a href="https://www.maps.ie/distance-area-calculator.html">
          distance maps
        </a>
      </iframe>
    </div>
  );
};

export default Location;
