import React from "react";
import { NavItem, Navbar, NavbarBrand } from "reactstrap";
import { getLogoImage, getInstagramLogo } from "./utils/navigation-utils";
import "./Navigation.scss";
import { Link } from "react-router-dom";

const Navigation = () => {
  const logo = getLogoImage();
  const instagram = getInstagramLogo();

  const args = {
    expand: "md",
    container: "fluid",
  };

  return (
    <Navbar {...args} className="app-nav">
      <div className="nav-left">
        <NavbarBrand href="/" className="nav-brand">
          <img id="nav-logo" src={logo.src} alt={logo?.alt} />
          <small>Kelsey Devine</small>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/kdevinetattoo/"
          >
            <img id="instagram-logo" src={instagram.src} alt={instagram?.alt} />
          </a>
        </NavbarBrand>
      </div>
      <div className="nav-right">
        <NavItem className="nav-inner">
          <Link className="nav-item" to="/">
            Home
          </Link>
          <Link className="nav-item" to="/gallery">
            Gallery
          </Link>
        </NavItem>
      </div>
    </Navbar>
  );
};

export default Navigation;
