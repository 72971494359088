import { cloneDeep, shuffle } from "lodash";

const homeImages = [
  {
    previewSrc: require("../photos/cropped/(edited) 0BFBF43F-8FC7-4A59-9837-AF55A4DC1ACA.png"),
    originalSrc: require("../photos/original/0BFBF43F-8FC7-4A59-9837-AF55A4DC1ACA.jpg"),
    altText: "mushroom butt",
  },
  {
    previewSrc: require("../photos/cropped/(edited) 47134462-A889-48F7-813F-386A85DB32DE.png"),
    originalSrc: require("../photos/original/47134462-A889-48F7-813F-386A85DB32DE.jpg"),
    altText: "frog mushroom",
  },
  {
    previewSrc: require("../photos/cropped/(edited) EEEB0636-8962-4D60-AE41-0A1A1D30A81D.png"),
    originalSrc: require("../photos/original/EEEB0636-8962-4D60-AE41-0A1A1D30A81D.JPG"),
    altText: "mom heart",
  },
  {
    previewSrc: require("../photos/cropped/(edited) tl (4).png"),
    originalSrc: require("../photos/original/tl (4).jpg"),
    altText: "chicken cup",
  },
  {
    previewSrc: require("../photos/cropped/(edited) tl (7).png"),
    originalSrc: require("../photos/original/tl (7).jpg"),
    altText: "dagger",
  },
  {
    previewSrc: require("../photos/cropped/(edited) tl (13).png"),
    originalSrc: require("../photos/original/tl (13).jpg"),
    altText: "eye hand",
  },
  {
    previewSrc: require("../photos/cropped/(edited) tl (30).png"),
    originalSrc: require("../photos/original/tl (30).jpg"),
    altText: "strawberries",
  },
  {
    previewSrc: require("../photos/cropped/(edited) tl (32).png"),
    originalSrc: require("../photos/original/tl (32).jpg"),
    altText: "gorgon",
  },
  {
    previewSrc: require("../photos/cropped/(edited) tl (47).png"),
    originalSrc: require("../photos/original/tl (47).jpg"),
    altText: "flowers",
  },
  {
    previewSrc: require("../photos/cropped/(edited) tl (48).png"),
    originalSrc: require("../photos/original/tl (48).jpg"),
    altText: "pumpkin",
  },
];

const getHomeImages = () => {
  const randomizedImages = shuffle(homeImages);
  return cloneDeep(randomizedImages);
};

const getPrideProps = () =>
  cloneDeep({
    src: require("../photos/misc/progressprideflag-danielquasar-highrez.png"),
    alt: "inclusive-pride-flag",
  });

const getBackgroundImage = () => {
  cloneDeep({
    previewSrc: require("../photos/misc/(edited) IMG_2240.png"),
    originalSrc: require("../photos/misc/IMG_2240.JPG"),
    altText: "background",
  });
};

const getLogoImages = () => {
  cloneDeep({
    previewSrc: require("../photos/misc/(edited) IMG_2241.png"),
    originalSrc: require("../photos/misc/IMG_2241.JPG"),
    altText: "logo big",
  });
};

const getInstagramLogo = () =>
  cloneDeep({
    src: require("../photos/misc/logo-ig-png-32464.png"),
    alt: "instagram logo",
  });

export {
  getHomeImages,
  getPrideProps,
  getBackgroundImage,
  getLogoImages,
  getInstagramLogo,
};
