import React, { useState } from "react";
import { map } from "lodash";
import {
  Container,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { getHomeImages } from "../Home/components/HomeGallery/utils/home-component-utils";
import "./Gallery.scss";

const items = getHomeImages();

const Gallery = () => {
  const [modal, setModal] = useState(false);
  const [modalImg, setModalImg] = useState("");
  const toggle = () => setModal(!modal);

  const handleHoverEnter = (e) => {
    e.target.style.height = "15.75rem";
  };
  const handleHoverExit = (e) => {
    e.target.style.height = "15.25rem";
  };

  return (
    <Container className="gallery-container">
      {map(items, (item) => (
        <Card
          className="gallery-card"
          key={item.previewSrc}
          onMouseEnter={handleHoverEnter}
          onMouseLeave={handleHoverExit}
          onClick={() => {
            setModalImg(item.originalSrc);
            setTimeout(() => {
              toggle();
            }, 100);
          }}
        >
          <CardBody className="gallery-card-body">
            <img src={item.previewSrc} alt={item.altText} id="gallery-item" />
          </CardBody>
        </Card>
      ))}
      <Modal className="gallery-modal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader
          className="gallery-modal-header"
          toggle={toggle}
        ></ModalHeader>
        <ModalBody className="gallery-modal-body">
          <img src={modalImg} alt="tattoo" id="gallery-modal-item" />
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default Gallery;
