import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Location } from "./components";
import HomeGallery from "./components/HomeGallery/HomeGallery";
import {
  getInstagramLogo,
  getPrideProps,
} from "./components/HomeGallery/utils/home-component-utils";
import "./Home.scss";
import AboutMe from "../AboutMe/AboutMe";

const Home = () => {
  const prideSrc = getPrideProps();
  const instagram = getInstagramLogo();
  const args = {
    color: "dark",
  };

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <Container className="home-container">
      <Card {...args} className="home-card">
        <CardBody className="home-card-body">
          <Col className="card-col header-col">
            <Row className="header-row">
              <h1>Kelsey Devine</h1>
              <h2>Tattoo Artist</h2>
              <Row className="card-row header-inner">
                Located at Tattoo Freddie's House of Ink
              </Row>
              <Row className="card-row header-inner">
                2203 Dunn Ave Suite 3, Jacksonville, FL 32218
              </Row>
              <Row className="card-row header-inner mb-2">
                Walk-ins Welcome!
              </Row>
              <h4>Art is for all skins tones.</h4>
              <h4>
                Safe Space, Trauma Informed, LGBTQIA+ Friendly{" "}
                <img
                  className="pride-flag"
                  src={prideSrc?.src}
                  alt={prideSrc?.alt}
                />
              </h4>
              <Row className="card-row header-inner mt-2">
                <div className="home-link">
                  <Button className="home-link-button" onClick={toggle}>
                    Click here to contact me!
                  </Button>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/kdevinetattoo/"
                  >
                    <img
                      id="instagram-logo"
                      src={instagram?.src}
                      alt={instagram?.alt}
                    />
                  </a>
                </div>
              </Row>
            </Row>
          </Col>
          <Col className="card-col">
            <Row className="card-row">
              <HomeGallery />
            </Row>
          </Col>
          <Col className="card-col" id="about">
            <Card className="about-me" color="dark">
              <AboutMe />
            </Card>
          </Col>
          <Col className="card-col">
            <Row className="card-row header-inner mb-3">
              <div className="home-link">
                <Button className="home-link-button" onClick={toggle}>
                  Click here to contact me!
                </Button>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/kdevinetattoo/"
                >
                  <img
                    id="instagram-logo"
                    src={instagram?.src}
                    alt={instagram?.alt}
                  />
                </a>
              </div>
            </Row>
            <h3>Location</h3>
            <Row className="card-row">
              <Location />
            </Row>
          </Col>
        </CardBody>
      </Card>
      <Modal className="contact-modal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="contact-modal-header" toggle={toggle}>
          Contact Form
        </ModalHeader>
        <ModalBody className="contact-modal-body">
          <iframe
            title="contact-form"
            className="contact-iframe"
            src="https://docs.google.com/forms/d/e/1FAIpQLScX3ws8M3IgwpMoeM7CyBFGq4Tzd9JqFo0j4wnj4UD3wrz1KA/viewform?embedded=true"
            style={{ overflow: "hidden", width: "auto", height: "100vh" }}
          >
            Loading…
          </iframe>
        </ModalBody>
        <ModalFooter className="contact-modal-footer">
          <Button onClick={toggle} className="home-link">
            Close Form
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default Home;
